
/* injects from baggage-loader */

import * as Crossing from 'crossing';
import { Translation } from './utils/Translation/Translation';

const ROUTES_MAP_LANG = {
    lt: {
        'home': '/',
        'events:filter': '/renginiai',
        'events': '/renginiai/:category_id/:event_type',
        'event': '/renginys/:event_slug/:event_id/:show_id',
        'event:noshow': '/renginys/:event_slug/:event_id',
        'event:tickets:club-lodge:pos': '/pirkti-bilietus/:event_id/:show_id/lozes-ir-klubai/:club_code',
        'event:tickets:club-lodge': '/pirkti-bilietus/:event_id/:show_id/lozes-ir-klubai',
        'event:tickets': '/pirkti-bilietus/:event_id/:show_id',
        'voucher': '/dovanu-kuponas/:slug/:voucher_id',
        'voucher:buy': '/dovanu-kuponai/pirkti/:voucher_id',
        'voucher:list': '/dovanu-kuponai',
        'user:tickets': '/vartotojas/bilietai',
        'user:settings': '/vartotojas/nustatymai',
        'user:favorite': '/vartotojas/megstami-renginiai',
        'user:club-lodge-events': '/vartotojas/lozes-ir-klubai',
        'user:activate': '/activate/:guid',
        'form:activate-newsletter': '/activate-newsletter/:guid',
        'user:change-password': '/change-password/:guid',
        'user:login': '/prisijunk',
        'cart': '/krepselis',
        'cart:payment': '/krepselis/apmoketi',
        'cart:id': '/krepselis/:cart_id',
        'cart:payment:error': '/krepselis/apmoketi/:error_code',
        'cart:payment:error:type': '/krepselis/apmoketi/:error_code/:error_type',
        'tickets': '/bilietai',
        'box-office:ticket-purchase-success': '/bilietai-jau-tavo',
        'box-office:ads': '/kasos-reklama/:carousel_id',
        'search': '/paieska',
        'box-office:return-ticket': '/kasa/bilietu-grazinimas',
        'box-office:return-ticket:show': '/kasa/bilietu-grazinimas/:event_id/:show_id',
        'groups': '/festivaliai',
        'group': '/festivalis/:group_id/:slug',
        'ticket-hub': '/bilietu-keitimas',
        'ticket-return': '/bilietu-grazinimas',
        'ticket-return:tax': '/bilietu-grazinimas/m',
        'ticket-change': '/bilietu-keitimas',
        'ticket-change:tax': '/bilietu-keitimas/m',
        'ticket-change-to-voucher': '/bilietu-kuponai',
        'personal-discounts': '/asmeniniai-pasiulymai',
        'static': '/:page_type',
        'news': '/naujienos',
        'news:slug': '/naujienos/:slug',
    },
    en: {
        'home': '',
        'events:filter': '/events',
        'events': '/events/:category_id/:event_type',
        'event': '/event/:event_slug/:event_id/:show_id',
        'event:noshow': '/event/:event_slug/:event_id',
        'event:tickets:club-lodge:pos': '/purchase/:event_id/:show_id/clubs-and-lounges/:club_code',
        'event:tickets:club-lodge': '/purchase/:event_id/:show_id/clubs-and-lounges',
        'event:tickets': '/purchase/:event_id/:show_id',
        'voucher': '/gift-card/:slug/:voucher_id',
        'voucher:buy': '/gift-cards/purchase/:voucher_id',
        'voucher:list': '/gift-cards',
        'user:tickets': '/user/tickets',
        'user:settings': '/user/info',
        'user:favorite': '/user/favorite',
        'user:club-lodge-events': '/user/clubs-and-lounges',
        'user:activate': '/activate/:guid',
        'form:activate-newsletter': '/activate-newsletter/:guid',
        'user:change-password': '/change-password/:guid',
        'user:login': '/signin',
        'cart': '/cart',
        'cart:payment': '/cart/checkout',
        'cart:id': '/cart/:cart_id',
        'cart:payment:error': '/cart/checkout/:error_code',
        'cart:payment:error:type': '/cart/checkout/:error_code/:error_type',
        'tickets': '/tickets',
        'box-office:ticket-purchase-success': '/your-tickets',
        'box-office:ads': '/box-office-ads/:carousel_id',
        'search': '/search',
        'box-office:return-ticket': '/box-office/return-ticket',
        'box-office:return-ticket:show': '/box-office/return-ticket/:event_id/:show_id',
        'groups': '/festivals',
        'group': '/festival/:group_id/:slug', // @FIXM nera slug'o
        'ticket-hub': '/ticket-change',
        'ticket-return': '/ticket-return',
        'ticket-return:tax': '/ticket-return/m',
        'ticket-change': '/ticket-change',
        'ticket-change:tax': '/ticket-change/m',
        'ticket-change-to-voucher': '/ticket-voucher',
        'personal-discounts': '/personal-discounts',
        'static': '/:page_type',
        'news': '/news',
        'news:slug': '/news/:slug',
    },
    bs: {
        'home': '',
        'events:filter': '/events',
        'events': '/events/:category_id/:event_type',
        'event': '/event/:event_slug/:event_id/:show_id',
        'event:noshow': '/event/:event_slug/:event_id',
        'event:tickets:club-lodge:pos': '/purchase/:event_id/:show_id/clubs-and-lounges/:club_code',
        'event:tickets:club-lodge': '/purchase/:event_id/:show_id/clubs-and-lounges',
        'event:tickets': '/purchase/:event_id/:show_id',
        'voucher': '/gift-card/:slug/:voucher_id',
        'voucher:buy': '/gift-cards/purchase/:voucher_id',
        'voucher:list': '/gift-cards',
        'user:tickets': '/user/tickets',
        'user:settings': '/user/info',
        'user:favorite': '/user/favorite',
        'user:club-lodge-events': '/user/clubs-and-lounges',
        'user:activate': '/activate/:guid',
        'form:activate-newsletter': '/activate-newsletter/:guid',
        'user:change-password': '/change-password/:guid',
        'user:login': '/signin',
        'cart': '/cart',
        'cart:payment': '/cart/checkout',
        'cart:id': '/cart/:cart_id',
        'cart:payment:error': '/cart/checkout/:error_code',
        'cart:payment:error:type': '/cart/checkout/:error_code/:error_type',
        'tickets': '/tickets',
        'box-office:ticket-purchase-success': '/your-tickets',
        'box-office:ads': '/box-office-ads/:carousel_id',
        'search': '/search',
        'box-office:return-ticket': '/box-office/return-ticket',
        'box-office:return-ticket:show': '/box-office/return-ticket/:event_id/:show_id',
        'groups': '/festivals',
        'group': '/festival/:group_id/:slug', // @FIXM nera slug'o
        'ticket-hub': '/ticket-change',
        'ticket-return': '/ticket-return',
        'ticket-return:tax': '/ticket-return/m',
        'ticket-change': '/ticket-change',
        'ticket-change:tax': '/ticket-change/m',
        'ticket-change-to-voucher': '/ticket-voucher',
        'personal-discounts': '/personal-discounts',
        'static': '/:page_type',
        'news': '/news',
        'news:slug': '/news/:slug',
    },
    ru: {
        'home': '',
        'events:filter': '/events',
        'events': '/events/:category_id/:event_type',
        'event': '/event/:event_slug/:event_id/:show_id',
        'event:noshow': '/event/:event_slug/:event_id',
        'event:tickets:club-lodge:pos': '/purchase/:event_id/:show_id/clubs-and-lounges/:club_code',
        'event:tickets:club-lodge': '/purchase/:event_id/:show_id/clubs-and-lounges',
        'event:tickets': '/purchase/:event_id/:show_id',
        'voucher': '/gift-card/:slug/:voucher_id',
        'voucher:buy': '/gift-cards/purchase/:voucher_id',
        'voucher:list': '/gift-cards',
        'user:tickets': '/user/tickets',
        'user:settings': '/user/info',
        'user:favorite': '/user/favorite',
        'user:club-lodge-events': '/user/clubs-and-lounges',
        'user:activate': '/activate/:guid',
        'form:activate-newsletter': '/activate-newsletter/:guid',
        'user:change-password': '/change-password/:guid',
        'user:login': '/signin',
        'cart': '/cart',
        'cart:payment': '/cart/checkout',
        'cart:id': '/cart/:cart_id',
        'cart:payment:error': '/cart/checkout/:error_code',
        'cart:payment:error:type': '/cart/checkout/:error_code/:error_type',
        'tickets': '/tickets',
        'box-office:ticket-purchase-success': '/your-tickets',
        'box-office:ads': '/box-office-ads/:carousel_id',
        'search': '/search',
        'box-office:return-ticket': '/box-office/return-ticket',
        'box-office:return-ticket:show': '/box-office/return-ticket/:event_id/:show_id',
        'groups': '/festivals',
        'group': '/festival/:group_id/:slug', // @FIXM nera slug'o
        'ticket-hub': '/ticket-change',
        'ticket-return': '/ticket-return',
        'ticket-return:tax': '/ticket-return/m',
        'ticket-change': '/ticket-change',
        'ticket-change:tax': '/ticket-change/m',
        'ticket-change-to-voucher': '/ticket-voucher',
        'personal-discounts': '/personal-discounts',
        'static': '/:page_type',
        'news': '/news',
        'news:slug': '/news/:slug',
    }
};

const getLanguageRouteMap = (language: string) => {
    const rm = Object.assign({}, ROUTES_MAP_LANG[language]);
    Object.keys(rm).map((route => { rm[route] = Translation.getLanguagePrefix(language) + rm[route]; }));
    return rm;
};

const getLanguageUrls = (language: string, routeMap) => {
    const urls = new Crossing(new RegExp(':([A-Za-z0-9-_%]{1,})', 'g'));
    urls.load(routeMap);
    return urls;
};

const ROUTES_MAP = getLanguageRouteMap(Translation.getCurrentLanguage());
const urls = getLanguageUrls(Translation.getCurrentLanguage(), ROUTES_MAP);

export { ROUTES_MAP, urls, getLanguageRouteMap, getLanguageUrls };
