
/* injects from baggage-loader */

const fileDownload = require('js-file-download');
import i18next from 'i18next';

import { CashRegister } from '../utils/cashRegister/cashRegister';
import { isCartEmpty } from '../selectors/cart';
import { API_URL, URL } from '../utils/api/constants';
import { apiActionCreator } from '../utils/api/apiActionCreator';
import { resetTickets } from './ticket';
import { resetSelectedVouchers } from './voucher';
import { history } from '../history';
import { urls } from '../routes';
import {
  CartStatus,
  IPurchaseRequest,
  PaymentTypesEnum,
  ICartShow,
  IInsuranceUpdate,
} from '../contracts/cart';
import { EveryPay } from '../utils/payments/everypay';
import { formValueSelector } from 'redux-form';
import { getVenue } from './venue';
import { hideAllModals, hideTopModal } from './modal';
import {
  hasAutoPayment,
  hasPOSIntegratedPayments,
  isBoxOfficeUser,
} from '../selectors/user';
import { clearMessages, setApiMessage, setMessagePayload } from './message';
import { log } from '../utils/logger';
import { setMessage } from './message';
import { NeoPayPayment } from '../utils/payments/neopay';
import { IDiscountRequest, IRemoveDiscountRequest } from '../contracts/tickets';

const CART_REFRESH = 5 * 1000; // 5sec to check incomplete orders
export const CART = {
  ADD: 'ADD_TO_CART',
  INSURANCE: 'INSURANCE',
  ADD_VOUCHERS: 'ADD_VOUCHERS_TO_CART',
  MOVE: 'MOVE_CART',
  GET: 'GET_CART',
  DELETE: 'DELETE_CART',
  DELETE_VOUCHERS: 'DELETE_VOUCHERS',
  CANCEL_BUY: 'CANCEL_BUY',
  GET_PAYMENT_TYPES: 'GET_PAYMENT_TYPES',
  CLEAR_CART: 'CLEAR_CART',
  REQUEST_PURCHASE: 'REQUEST_PURCHASE',
  BOX_OFFICE_REQUEST_PURCHASE: 'BOX_OFFICE_REQUEST_PURCHASE',
  BOX_OFFICE_REQUEST_PRE_PURCHASE_RECEIPT:
    'BOX_OFFICE_REQUEST_PRE_PURCHASE_RECEIPT',
  GET_PAYMENT_INFO: 'GET_PAYMENT_INFO',
  GET_SUMMARY_PDF_REQUEST: 'GET_SUMMARY_PDF_REQUEST',
  GET_SUMMARY_PDF_SUCCESS: 'GET_SUMMARY_PDF_SUCCESS',
  GET_SUMMARY_PDF_FAILURE: 'GET_SUMMARY_PDF_FAILURE',
  ADD_PHYSICAL_DELIVERY: 'ADD_PHYSICAL_DELIVERY',
  REMOVE_PHYSICAL_DELIVERY: 'REMOVE_PHYSICAL_DELIVERY',
  GET_PAYMENT_STATUS: 'GET_PAYMENT_STATUS',
  GET_RESERVATION_PAYMENT_CART: 'GET_RESERVATION_PAYMENT_CART',
  GET_DISCOUNT: 'GET_DISCOUNT',
  SET_DISCOUNT: 'SET_DISCOUNT',
  SET_ACTIVE_DISCOUNT: 'SET_ACTIVE_DISCOUNT'
};

export function requestPurchase(body: IPurchaseRequest) {
  return (dispatch, getState) => {
    body.boxOffice = isBoxOfficeUser(getState());
    dispatch(
      apiActionCreator({
        endpoint: API_URL + '/cart/buy',
        method: 'POST',
        type: CART.REQUEST_PURCHASE,
        body,
        onSuccess: (args) => {
          if (args.bankName) {
            if (args.bankName === PaymentTypesEnum.NEOPAY) {
              new NeoPayPayment(args.bankMessage).submit();
            } else if (args.bankName === PaymentTypesEnum.EVERYPAY) {
              new EveryPay(args.bankUrl).submit();
            }
          } else {
            history.push(urls.get('tickets'));
            dispatch(hideAllModals());
            dispatch({
              type: CART.CLEAR_CART,
            });
          }
        },
      })
    );
  };
}

export function requestApplePayPurchase(body: IPurchaseRequest) {
  return apiActionCreator({
    endpoint: API_URL + `/cart/prepareMobile?email=${body.email}&boxOffice=${body.boxOffice}`,
    method: 'POST',
    type: CART.REQUEST_PURCHASE,
  });
}

export function editApplePayPurchase(body: IPurchaseRequest) {
  return apiActionCreator({
    endpoint: API_URL + '/cart/paymentInfo',
    method: 'POST',
    type: CART.REQUEST_PURCHASE,
    body,
  });
}

export function requestBoxOfficePurchase(body: IPurchaseRequest) {
  return (dispatch, getState) => {
    const _hasPOSIntegratedPayments = hasPOSIntegratedPayments(getState());
    dispatch(
      apiActionCreator({
        endpoint: API_URL + '/cart/buy',
        method: 'POST',
        type: CART.BOX_OFFICE_REQUEST_PURCHASE,
        body,
        onSuccess: (args) => {
          if (
            _hasPOSIntegratedPayments &&
            body.paymentType &&
            !!body.paymentType.match(/card/gi)
          ) {
            history.push(urls.get('tickets') + `?noprint=1&orderId=${body.id}`);
          } else {
            history.push(urls.get('tickets') + `?orderId=${body.id}`);
          }
          dispatch(hideAllModals());
          dispatch({
            type: CART.CLEAR_CART,
          });
        },
      })
    );
  };
}

export function requestBoxOfficePrePurchaseReceipt(
  body: IPurchaseRequest,
  onErrorCb?: (args) => void
) {
  return (dispatch, getState) => {
    dispatch(
      apiActionCreator({
        endpoint: API_URL + '/cart/receipt',
        method: 'POST',
        type: CART.BOX_OFFICE_REQUEST_PRE_PURCHASE_RECEIPT,
        onError: onErrorCb,
        body,
        onSuccess: (args) => {
          if (args.receipt) {
            CashRegister.printReceipt(args.receipt, {
              onSuccess: () => {
                dispatch(requestBoxOfficePurchase(body));
              },
              onError: (message) => {
                log(message);
                dispatch(
                  setMessage(
                    i18next.t('POS Klaida spausdinant kvitą'),
                    `${message}. Mėginkite dar kartą`
                  )
                );
              },
            });
          }
        },
      })
    );
  };
}

export function clearCart() {
  const urlsResolved = urls.resolve(history.location.pathname);
  if (urlsResolved.name && urlsResolved.name.slice(0, 4) === 'cart') {
    history.push(urls.get('home'));
  }
  return (dispatch, getState) => {
    if (urlsResolved.name === 'event:tickets' && urlsResolved.kwargs.show_id) {
      const state = getState();
      const show = state.shows.byId[urlsResolved.kwargs.show_id];
      if (show) {
        dispatch(getVenue(show.id));
      }
    }
    dispatch({
      type: CART.CLEAR_CART,
    });
  };
}

export function getPaymentTypes(cartId: string) {
  return (dispatch, getState) => {
    const _isBoxOfficeUser = isBoxOfficeUser(getState());
    dispatch(
      apiActionCreator({
        endpoint:
          API_URL +
          `/cart/${cartId}/paymentTypes?boxOffice=${_isBoxOfficeUser}`,
        method: 'GET',
        type: CART.GET_PAYMENT_TYPES,
      })
    );
  };
}

export function deleteCartItem(ids: Array<string>, callback: () => void) {
  return (dispatch) => {
    dispatch(
      apiActionCreator({
        endpoint: API_URL + '/cart',
        method: 'DELETE',
        type: CART.DELETE,
        body: ids,
        onSuccess: () => {
          callback();
        },
        onError: (_, res) => {
          if (res.status === 404) {
            dispatch(
              setApiMessage(
                CART.DELETE,
                '',
                res.status,
                i18next.t('Suformuotos rezervacijos keisti negalite.')
              )
            );
          }
        },
      })
    );
  };
}

export function deleteCartGiftVouchers(
  ids: Array<string>,
  callback: () => void
) {
  return apiActionCreator({
    endpoint: API_URL + '/cart/giftVoucher',
    method: 'DELETE',
    type: CART.DELETE_VOUCHERS,
    body: ids,
    onSuccess: () => {
      callback();
    },
  });
}

export function moveCart(sessionId, fromLogin: boolean = false) {
  return (dispatch, getState) => {
    dispatch(
      apiActionCreator({
        endpoint: API_URL + '/cart/movecart',
        method: 'PUT',
        type: CART.MOVE,
        body: sessionId,
        onSuccess: () => {
          const state = getState();
          const selector = formValueSelector('checkout');
          const paymentType = selector(state, 'paymentType');
          if (paymentType) {
            dispatch(getCart(fromLogin, paymentType));
          } else {
            dispatch(getCart(fromLogin));
          }
        },
      })
    );
  };
}

export function updateInsurance(
  ticketsChanged: Array<IInsuranceUpdate>,
  callback?: (ticketIds: Array<ICartShow>) => void
) {
  return (dispatch) => {
    dispatch(
      apiActionCreator({
        endpoint: API_URL + '/cart/insurance',
        method: 'POST',
        type: CART.INSURANCE,
        body: ticketsChanged,
        onSuccess: (args) => {
          callback && callback(args.shows);
          dispatch(resetTickets());
        },
        onError: (_, res) => {
          if (res.status === 404) {
            dispatch(
              setApiMessage(
                CART.INSURANCE,
                '',
                res.status,
                i18next.t('Suformuotos rezervacijos keisti negalite.')
              )
            );
          }
        },
      })
    );
  };
}

export function addToCart(
  ticketIds: Array<string>,
  callback: (ticketIds: Array<ICartShow>) => void,
  onError?: () => void
) {
  return (dispatch) => {
    dispatch(
      apiActionCreator({
        endpoint: API_URL + '/cart',
        method: 'POST',
        type: CART.ADD,
        body: ticketIds,
        onSuccess: (args) => {
          callback(args.shows);
          dispatch(resetTickets());
          history.push(urls.get('cart:id', { cart_id: args.id }));
        },
        onError
      })
    );
  };
}

export function addVouchersToCart(ids: Array<string>, callback: () => void) {
  return (dispatch) => {
    dispatch(
      apiActionCreator({
        endpoint: API_URL + '/cart/giftVoucher',
        method: 'POST',
        type: CART.ADD_VOUCHERS,
        body: ids,
        onSuccess: (args) => {
          callback();
          dispatch(resetSelectedVouchers());
          history.push(urls.get('cart:id', { cart_id: args.id }));
        },
      })
    );
  };
}

let CART_TIMER;
const maybeRefreshCart = (cart, dispatch, state) => {
  if (cart.status === CartStatus.WAITINGFORPAYMENT) {
    clearTimeout(CART_TIMER);
    CART_TIMER = setTimeout(() => {
      const selector = formValueSelector('checkout');
      const paymentType = selector(state, 'paymentType');
      if (paymentType) {
        dispatch(getCart(false, paymentType));
      } else {
        dispatch(getCart());
      }
    }, CART_REFRESH);
  }
};

const maybeAfterAutoPayment = (cart, dispatch) => {
    if (cart.status === CartStatus.PURCHASED) { // maxima
        if (urls.get('tickets') !== history.location.pathname) {
            history.push(urls.get('tickets'));
        }
        dispatch(clearMessages());
        dispatch({
            type: CART.CLEAR_CART
        });
    }
};

let REFRESH_CART_TIMER;
const waitCartAutoPayment = () => {
  clearTimeout(REFRESH_CART_TIMER);
  return (dispatch, getState) => {
    const cart = getState().cart;
    if (!isCartEmpty(cart)) {
      REFRESH_CART_TIMER = setTimeout(() => {
        dispatch(getCart());
        dispatch(waitCartAutoPayment());
      }, CART_REFRESH);
    }
  };
};

export function getCart(fromLogin: boolean = false, paymentType?: string): any {
  return (dispatch, getState) => {
    const state = getState();
    const cart = state.cart;
    let endpoint = API_URL + '/cart';
    // fromLogin comes after moveCart.
    // keep it without cart.id, because it was moved and now empty
    if (cart && cart.id && fromLogin === false) {
      endpoint = API_URL + '/cart/' + encodeURIComponent(cart.id);
    }
    dispatch(
      apiActionCreator({
        endpoint,
        method: 'GET',
        type: CART.GET,
        body: paymentType && {
          paymentType,
        },
        onSuccess: (args) => {
          maybeRefreshCart(args, dispatch, state);
          maybeAfterAutoPayment(args, dispatch);
          if (fromLogin && args && args.grandTotal > 0) {
            setTimeout(() => history.push(urls.get('cart:payment')), 100);
          }
        },
      })
    );
  };
}

export function getCartById(cartId: string): any {
  return (dispatch, getState) => {
    dispatch(
      apiActionCreator({
        endpoint: API_URL + '/cart/' + encodeURIComponent(cartId),
        method: 'GET',
        type: CART.GET,
        onSuccess: (args) => {
          maybeRefreshCart(args, dispatch, getState());
        },
      })
    );
  };
}

export function getPaymentInfoByCartId(cartId: string, cb?: () => void) {
  return apiActionCreator({
    endpoint: API_URL + '/cart/invoiceInfo/' + encodeURIComponent(cartId),
    method: 'GET',
    type: CART.GET_PAYMENT_INFO,
    onSuccess: cb,
  });
}

export function cancelBuy(cartId: string, cb?: () => void, origin?: string) {
  return apiActionCreator({
    endpoint: API_URL + `/cart/cancelbuy?origin=${origin}`,
    method: 'POST',
    type: CART.CANCEL_BUY,
    onSuccess: cb,
    body: cartId,
  });
}

export function getReservationPaymentCart(reservationNumber: string) {
  return (dispatch) => {
    dispatch(
      apiActionCreator({
        endpoint: `${API_URL}/cart/reservation/${reservationNumber}`,
        method: 'GET',
        type: CART.GET_RESERVATION_PAYMENT_CART,
        onSuccess: (args) => {
          history.push(urls.get('cart:id', { cart_id: args.id }));
          dispatch(hideTopModal());
        },
      })
    );
  };
}

export function getCartSummary(email: string) {
  return (dispatch, getState) => {
    const cartId = getState().cart.id;
    const grandTotal = getState().cart.grandTotal;
    const userHasAutoPay = hasAutoPayment(getState());
    dispatch(
      apiActionCreator({
        endpoint: `${API_URL}/cart/summary`,
        method: 'POST',
        type: [
          CART.GET_SUMMARY_PDF_REQUEST,
          {
            type: CART.GET_SUMMARY_PDF_SUCCESS,
            payload: (action, state, res) => {
              if (userHasAutoPay && grandTotal > 0) {
                dispatch(
                  setMessagePayload({
                    actionType: 'AUTO_PAYMENT',
                  })
                );
                dispatch(waitCartAutoPayment());
              }
              return res.blob().then((blob) => {
                fileDownload(blob, i18next.t('kakava-lt_rezervacija.pdf'));
              });
            },
          },
          CART.GET_SUMMARY_PDF_FAILURE,
        ],
        body: {
          cartId,
          email
        }
      })
    );
  };
}

export function createPhysicalDelivery(cardId, body) {
  return (dispatch) => {
    dispatch(
      apiActionCreator({
        endpoint: `${API_URL}/cart/delivery/${cardId}`,
        method: 'POST',
        body,
        type: CART.ADD_PHYSICAL_DELIVERY,
        onSuccess: () => {
          dispatch(hideAllModals());
        },
      })
    );
  };
}

export function removePhysicalDelivery(cardId) {
  return (dispatch) => {
    dispatch(
      apiActionCreator({
        endpoint: `${API_URL}/cart/delivery/${cardId}`,
        method: 'DELETE',
        type: CART.ADD_PHYSICAL_DELIVERY,
      })
    );
  };
}

export function getPaymentStatus(orderRef, paymentRef, cb?: () => void) {
  return (dispatch) => {
    dispatch(
      apiActionCreator({
        endpoint: `${URL}/ipay/everypay?order_reference=${orderRef}&payment_reference=${paymentRef}`,
        method: 'GET',
        type: CART.GET_PAYMENT_STATUS,
        onSuccess: (args) => {
          if (args.cartStatus === CartStatus.PAID) {
            history.push('/bilietai');
          } else {
            history.push('/krepselis/apmoketi/PaymentRejected');
          }
        },
      })
    );
  };
}

export function getDiscounts(): any {
  return (dispatch, getState) => {
    const _isBoxOfficeUser = isBoxOfficeUser(getState());
    dispatch(
      apiActionCreator({
        endpoint: API_URL + `/cart/discount?boxOffice=${_isBoxOfficeUser}`,
        method: 'GET',
        type: CART.GET_DISCOUNT,
        onSuccess: (args) => {
        },
      })
    );
  };
}

export function setDiscount(body: IDiscountRequest) {
  return (dispatch, getState) => {
      body.boxOffice = isBoxOfficeUser(getState());
      dispatch(apiActionCreator({
          endpoint: API_URL + '/cart/discount',
          type: CART.SET_DISCOUNT,
          method: 'PUT',
          body,
          onSuccess: () => {
            dispatch(hideTopModal());
          }
      }));
  };
}

export function removeDiscount(body: IRemoveDiscountRequest) {
  return (dispatch, getState) => {
      body.boxOffice = isBoxOfficeUser(getState());
      dispatch(apiActionCreator({
          endpoint: API_URL + '/cart/discount',
          type: CART.SET_DISCOUNT,
          method: 'DELETE',
          body,
      }));
  };
}

