
/* injects from baggage-loader */

const DEFAULT_LANGUAGE = 'lt';
const FALLBACK_LANGUAGE = 'lt';
const ACTIVE_LANGUAGES = ['lt', 'en', 'bs', 'ru'];
const LANGUAGE_LOCALE_MAP = {lt: 'lt_LT', en: 'en_US', bs: 'bs', ru: 'ru_RU'};
const ACTIVE_LANGUAGES_OBJ = {lt: '', en: '', bs: '', ru: ''};

export { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE, ACTIVE_LANGUAGES, LANGUAGE_LOCALE_MAP, ACTIVE_LANGUAGES_OBJ };
