
/* injects from baggage-loader */

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as moment from 'moment';
import 'moment/locale/lt';
import Validators from 'redux-form-validators';

import { Translation } from './utils/Translation/Translation';
import { FALLBACK_LANGUAGE, ACTIVE_LANGUAGES } from './constants/i18n';
import { en } from './i18n/en/translation';
import { bs } from './i18n/bs/translation';
import { ru } from './i18n/ru/translation';

const currentLanguage = Translation.getCurrentLanguage();

i18next
    .use(initReactI18next)
    .init({
        resources: {
            en,
            bs,
            ru
        },
        debug: false,

        whitelist: ACTIVE_LANGUAGES,
        lng: currentLanguage,
        fallbackLng: FALLBACK_LANGUAGE,
        load: 'languageOnly',

        ns: ['translation'],
        defaultNS: 'translation',

        nsSeparator: false,
        keySeparator: false,

        react: {
            wait: true
        }
    });

moment.updateLocale('lt', {
    monthsShort : [
        'sau', 'vas', 'kov', 'bal', 'geg', 'bir', 'lie', 'rugpj', 'rugs', 'spa', 'lap', 'gruod'
    ]
});

Object.assign(Validators.messages, {
    presence: {
        id: 'form.errors.presence',
        defaultMessage: i18next.t('Privalomas laukas'),
    },
    email: {
        id: 'form.errors.email',
        defaultMessage: i18next.t('Nurodytas neteisingas el.pašto adresas.'),
    },
    acceptance: {
        id: 'form.errors.acceptance',
        defaultMessage: i18next.t('Turi būti pažymėta'),
    },
    confirmation: {
        id: 'form.errors.confirmation',
        defaultMessage: i18next.t('Nesutampa su {fieldLabel}'),
    },
    tooShort: {
        id: 'form.errors.tooShort',
        defaultMessage: i18next.t('Per trumpas (bent {count, number} {count, plural, one {simbolis} other {simboliai}})'),
    },
    tooLong: {
        id: 'form.errors.tooLong',
        defaultMessage: i18next.t('Per ilgas (iki {count, number} {count, plural, one {simbolio} other {simbolių}})'),
    },
});

moment.locale(currentLanguage);

export default i18next;
