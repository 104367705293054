
/* injects from baggage-loader */

/* tslint:disable:TS2695 */
export const ru = {
    translation: {
        " | kakava.lt": " | ",
        "Aaaaaah! Tokio puslapio nėra :(": "Ааааа! ",
        "Adresas": "Адрес",
        "Akcija!": "Распродажа!",
        "Akcija": "Специальное предложение",
        "Akcijos": "Специальные предложения",
        "Aktyvacijos klaida": "Ошибка активации",
        "Amžiaus cenzas": "Возраст согласия",
        "Apie atlikėją": "Об исполнителе",
        "Apie organizatorių": "Об организаторе мероприятия",
        "Apie renginį": "Информация о событии",
        "Apmokėjimas": "Оплата",
        "Apmokėtas": "Оплаченный",
        "Apmokėti galėsite dovanų kuponu, kortele arba internetine bankininkyste": "Вы сможете оплатить подарочным сертификатом, картой или онлайн-банкингом.",
        "Apmokėti": "Платить",
        "Ar krepšelis apmokėtas?": "Корзина оплачена?",
        "Ar norite anuliuoti krepšelį?": "Хотите отменить корзину?",
        "Asmeniniai duomenys": "Личные данные",
        "Atraskite muzikos, teatro, kultūrinius ir sporto renginius, kurių bilietus įsigysite paprasčiau, greičiau ir patogiau per kakava.lt": "Откройте для себя музыкальные, театральные, культурные и спортивные мероприятия и покупайте билеты проще, быстрее и удобнее на kakava.lt",
        "Atsijungti": "Выйти",
        "Atsisiųsti pažymėtus bilietus": "Скачать проверенные билеты",
        "Atsisiųsti pažymėtus kuponus": "Загрузите выбранные подарочные карты",
        "Pridėti draudimą visiems bilietams": "Добавить страховку ко всем билетам",
        "Bilietų draudimas": "Страхование билетов",
        "Gaukite kompensaciją už nepanaudotą bilietą su bilietų draudimu!": "Получите возмещение за неиспользованный билет со страховкой билета!",
        "Plačiau apie sąlygas sužinokite čia": "Подробнее об условиях читайте здесь",
        "Atsisiųsti visus bilietus": "Скачать все билеты",
        "Atsisiųsti visus kuponus": "Скачать все подарочные карты",
        "Atsisiųsti": "Скачать",
        "Atšaukta": "Отменено",
        "Atšauktas renginys": "Отмененное мероприятие",
        "Atšaukti": "Отмена",
        "Bilietai išsiųsti": "Билеты отправлены",
        "Bilietai jau tavo!": "Билеты ваши!",
        "Bilietai nerasti": "Билеты не найдены",
        "Bilietai spausdinimui": "Билеты в печать",
        "Bilietai sėkmingai išsiųsti draugui!": "Билеты успешно отправлены другу!",
        "Bilietai į renginius internetu: koncertai, teatras, pramogos": "Онлайн билеты на мероприятия: концерты, театр, развлечения, спорт",
        "Bilietai į renginius – paprasčiau nei paprastai! Greitas bilietų pirkimo procesas – su kakava.lt! Muzika, teatras, kultūros ir sporto renginiai.": "Билеты на мероприятия – проще, чем обычно! ",
        "Bilietai": "Билеты",
        "Bilietams ir sąskaitai atsisiųsti liko": "Оставшееся время для загрузки билетов и счета-фактуры",
        "Bilietas": "Билет",
        "Bilieto kaina": "Стоимость билета",
        "Bilietus išsiuntėme el. paštu": "Письмо с билетами отправлено",
        "Bilietus į populiariausius muzikos, teatro, kultūros ir sporto renginius įsigykite greičiau ir paprasčiau su kakava.lt – pažangiu bilietų platintoju.": "Приобретайте билеты на самые популярные музыкальные, театральные, культурные и спортивные мероприятия быстрее и проще с kakava.lt – инновационным продавцом билетов.",
        "Bilietų kainos": "Цены",
        "Bilietų pardavimas pasibaigęs": "Продажа билетов закончилась",
        "Buto Nr.": "Квартира №",
        "Dalinkis su draugais": "Поделитесь с друзьями",
        "Data nuo iki": "Дата",
        "Data": "Дата",
        "Daugiau": "Более",
        "Dirbame kasdien 8-22 val.": "Режим работы: ежедневно с 8:00 до 22:00.",
        "Dovanų kuponai": "Подарочные карты",
        "Duomenys atnaujinti": "Данные обновлены",
        "Duomenys sąskaitai faktūrai": "Детали счета",
        "Durys atidaromos": "Дверь открывается раньше",
        "Eilė": "Ряд",
        "El. laiškas su slaptažodžio priminimo nuoroda išsiųstas.": "Было отправлено электронное письмо со ссылкой для напоминания пароля.",
        "El. paštas sąskaitai": "Электронная почта для счета",
        "El. paštas": "Электронная почта",
        "Esi užsiregistravęs?": "Вы подписаны?",
        "Filtruoti renginius": "Фильтровать события",
        "Filtruoti": "Фильтр",
        "Fizinis asmuo": "Индивидуальный",
        "Galimos dovanų kuponų kainos": "Доступные цены на подарочные карты",
        "Gimimo metai": "Год рождения",
        "Grąža": "Изменять",
        "Grįžti atgal": "Назад",
        "Grįžti į pradinį langą": "Вернуться на домашнюю страницу",
        "Grįžti į renginį": "Вернуться к событию",
        "Ieškokite miesto": "Поиск города",
        "Ieškoti renginių": "Поиск событий",
        "Ieškoti": "Поиск",
        "Iki": "К",
        "Informacija apie renginį": "Информация о событии",
        "<0>Informuojame, kad šioje svetainėje naudojami slapukai (angl. cookies). Tęsdami naršymą šioje svetainėje, jūs sutinkate su mūsų Privatumo politika.</0>Daugiau apie <2>Privatumo politiką</2>.": "<0>Этот сайт использует файлы cookie. </0> Подробнее о <2>политика конфиденциальности</2>.",
        "Informuojame, kad šioje svetainėje naudojami slapukai (angl. cookies). Tęsdami naršymą šioje svetainėje, jūs sutinkate su mūsų Privatumo politika.": "Этот сайт использует файлы cookie. ",
        "Internetinė bankininkystė": "Интернет-банкинг",
        "Interneto puslapis": "Веб-сайт",
        "Iš viso": "Общий",
        "Išparduota": "Распроданный",
        "Išsaugoti": "Сохранять",
        "Išvalyti duomenis": "Очистить детали",
        "Išvalyti": "Прозрачный",
        "Jau <1>{{eventDate}}</1>d. <3>{{locationName}}</3> išvysite „<5>{{eventTitle}}</5>“. Renginio bilietus įsigysite greičiau per kakava.lt": "Уже включено <1>{{eventDate}}</1>д. <3>{{locationName}}</3> ты увидишь»<5>{{eventTitle}}</5>«. ",
        "Jei dar nesi sumokėjęs, <1>spausk čia</1>": "Если вы еще не заплатили, <1>нажмите здесь</",
        "Juridinis asmuo": "Юридическое лицо",
        "Kaina nuo": "Цена от",
        "Kaina": "Цена",
        "Kakava LT © 2018": "Какава ЛТ © 2018",
        "Kasa": "Театральная касса",
        "Keisti sektorių": "Сменить сектор",
        "Keisti vietas": "Пересесть",
        "Keisti vietą ir laiką": "Изменить место и время",
        "Skirtingos lokacijos": "Несколько мест",
        "Kita": "Другой",
        "Kiti sektoriai": "Другие сектора",
        "Kiti siūlomi renginiai": "Другие доступные события",
        "Koncertų, teatro, kultūros ir sporto renginių naujienos bei jų bilietai – vienoje vietoje. Susipažinkite su kakava.lt": "Билеты на новые концерты, театральные, культурные и спортивные мероприятия в одном месте. ",
        "Kontaktai": "Контакты",
        "Kortelės kodas": "Код карты",
        "Krepšelis laukia apmokėjimo.": "Корзина ожидает оплаты.",
        "<0>Krepšelis tuščias</0><1>ir išalkęs bilietų. Papildom?</1>": "<0>Корзина пуста</0><1>и жажду билетов. </1>",
        "Krepšelis": "Корзина",
        "Kvitas nerastas": "Квитанция не найдена",
        "Kuponai": "Подарочные карты",
        "kuponai": "подарочные карты",
        "kuponas": "подарочная карта",
        "Kuponas": "Подарочная карта",
        "kuponų": "Подарочные карты",
        "Kuponai jau tavo!": "Подарочные карты ваши!",
        "Kuponus išsiuntėme el. paštu": "Мы отправили ваши подарочные карты по электронной почте.",
        "Lange bilietai atsiras iškart, kai sugeneruosime.": "Тикеты появятся в окне сразу, как только мы их сгенерируем.",
        "Liko laiko": "Оставшееся время",
        "Lvovo g. 89-79, Vilnius": "Львово ул. ",
        "Kalba": "Язык",
        "Mano bilietai": "Мои билеты",
        "Mano duomenys": "Мои данные",
        "Mano krepšelis": "Моя корзина",
        "Mano mėgstami renginiai": "Мои любимые события",
        "Mažiau": "Меньше",
        "Miestas": "Город",
        "Mobilieji mokėjimai": "Мобильные платежи",
        "Mokėjimo kortelė": "Платежная карта",
        "Mokėjimui atlikti turi 20 minučių": "У вас есть 20 минут на оплату",
        "Mėgstami renginiai": "Любимые события",
        "Namo Nr.": "Дом №",
        "Naujas slaptažodis": "Новый пароль",
        "Naujiena": "Новый",
        "Ačiū, kad prenumeruoji kakava.lt naujienlaiškį. Prisijunk prie savo pašto dėžutės ir patvirtink kad sutinki gauti kakava.lt  naujienas.": "Благодарим вас за подписку на рассылку новостей kakava.lt. ",
        "Tavo patvirtinimą gavome, nuo šiol visas naujienas sužinosi pirmas!": "Мы получили ваше подтверждение, теперь вы будете узнавать все новости первыми!",
        "Naujienlaiškio aktyvacija": "Активация новостной рассылки",
        "Naujienlaiškio aktyvacija nepavyko.": "Активация рассылки не удалась.",
        "Naujienlaiškis": "Информационный бюллетень",
        "Naujienos": "Новости",
        "Ne": "Нет",
        "Neapmokėtas": "Неоплачиваемый",
        "Nerasta": "Не найдено",
        "Nesutampa su {fieldLabel}": "Не совпадает с {fieldLabel}",
        "Neturi prisijungimo?": "Впервые на kakava.lt? ",
        "Nori rinktis daugiau renginių?": "Хотите выбрать больше событий?",
        "Norint įsigyti daugiau nei 10 bilietų, prašome kreiptis": "Для приобретения более 10 билетов обращайтесь",
        "Noriu gauti kakava.lt  ir / arba renginio organizatoriaus naujienas": "Я хочу получать рассылку новостей kakava.lt и/или промоутера",
        "Nesutinku, kad mano kontaktiniai duomenys būtų naudojami panašių prekių ar paslaugų rinkodarai": "Я не согласен на передачу моей контактной информации в целях маркетинга аналогичных продуктов или услуг.",
        "Pritaikyti nuolaidą": "Применить скидку",
        "Keisti nuolaidą": "Изменить скидку",
        "Norėdamas pasirinkti vietą, spausk ant jos salės plane": "Чтобы выбрать место, щелкните его на плане зала.",
        "Nuo brangiausio iki pigiausio": "Цена: сначала самая высокая",
        "Nuo greičiausiai prasidedančio": "Время: начало в ближайшее время",
        "Nuo pigiausio iki brangiausio": "Цена: сначала самая низкая",
        "Nuo vėliausiai prasidedančio": "Время: начиная с самого последнего",
        "Nuo": "От",
        "Nuolaida pritaikyta": "Скидка применена",
        "Nuolaidos kodas": "Код скидки",
        "Nuolaidos": "Скидки",
        "Nurodyk, kur siųsti bilietus": "Сообщите нам, куда отправить билеты",
        "Nurodyta priimama suma yra mažesnė nei krepšelio suma.": "Указанная принимаемая сумма меньше суммы корзины.",
        "Nurodytas neteisingas el.pašto adresas.": "Неверный адрес электронной почты.",
        "Nurodyti el.pašto adresai turi sutapti.": "Указанные адреса электронной почты должны совпадать.",
        "Nurodytos slaptažodžių reikšmės turi sutapti.": "Указанные значения пароля должны совпадать.",
        "O ne! Apmokėjimas nepavyko. Pasirink kitą mokėjimo būdą ir pamėgink dar kartą.": "О, нет! ",
        "O ne! Kažkas nutiko. Klaida yra dalis progreso, atkreipsime į ją dėmesį, o tu pamėgink dar kartą.": "О, нет! ",
        "O ne! Neradome nieko, kas atitiktų filtrą. Pamėgink nurodyti kitą reikšmę.": "О, нет! ",
        "O ne! Nieko neradome. Paieškai reikia mažiausiai trijų simbolių, tad pamėgink dar kartą.": "О, нет! ",
        "O ne! Nieko neradome. Pamėgink patikrinti rašybą arba ieškoti pagal atlikėjo, renginio, organizatoriaus ar arenos pavadinimą.": "О, нет! ",
        "OK, mokėjimą atlikote": "ОК, вы произвели оплату",
        "Oops. Matom, kad mokėjimas atmestas. Pirmas blynas kartais prisvyla – bandom apmokėti kitu būdu?": "Упс. ",
        "Operacija baigta.": "Операция завершена.",
        "Ouch. Atsitrenkėm į klaidą iš banko, bičiuli. Bandyk kitą mokėjimo variantą.": "Ой. ",
        "Ouch. Atsitrenkėm į klaidą, bičiuli. Pirmas blynas kartais prisvyla – bandom apmokėti kitu būdu?": "Ой. ",
        "Ouch. Atsitrenkėm į neaiškią klaidą iš banko, bičiuli. Bandyk kitą mokėjimo variantą.": "Ой. ",
        "Pasirink kuponus": "Выберите подарочные карты",
        "Pasirinkite dovanų kuponą": "Выберите подарочную карту",
        "POS Klaida spausdinant bilietus": "Ошибка POS при печати билетов",
        "POS Klaida spausdinant kvitą": "Ошибка POS при печати чека",
        "POS Klaida": "Ошибка POS-терминала",
        "POS Spausdinimas": "POS-печать",
        "PVM kodas": "номер НДС",
        "PVM mokėtojo kodas turi būti sudarytas iš 11-14 simbolių.": "Номер НДС должен состоять из 11–14 символов.",
        "Paieška": "Поиск",
        "Paieškos rezultatai pagal užklausą": "Результаты поиска по запросу",
        "Pakartok el. paštą": "Повторно введите адрес электронной почты",
        "Pakartok naują slaptažodį": "Повторно введите новый пароль",
        "Pakeisti slaptažodį": "Изменить пароль",
        "Pamiršai slaptažodį? Kam nepasitaiko, bičiuli. Įvesk savo el. pašto adresą.": "Забыли пароль? ",
        "Pamiršau slaptažodį": "Забыли пароль",
        "Pardavimų ataskaita": "Отчет о продажах",
        "Pasibaigęs": "Срок действия мероприятия истек",
        "Pasirink apmokėjimo būdą": "Выберите способ оплаты",
        "Pasirink ataskaitos laikotarpį": "Выберите отчетный период",
        "Pasirink bilietus": "Выбрать билеты",
        "Pasirink bilietą": "Выбрать билет",
        "Pasirink miestą": "Выберите город",
        "Pasirink nuolaidą": "Выберите скидку",
        "Pasirink sektorių": "Выберите сектор",
        "Pasirink tau priklausančią nuolaidą": "Выберите свою скидку",
        "Pasirink vietą": "Выберите место",
        "Pasirink": "Выбирать",
        "Pavardė": "Фамилия",
        "Pašalinti": "Удалять",
        "Pašto kodas": "Почтовый индекс",
        "Per ilgas (iki {count, number} {count, plural, one {simbolio} other {simbolių}})": "Слишком длинный (до {count, число} {count, множественное число, один {символ} другой {символы}})",
        "Per trumpas (bent {count, number} {count, plural, one {simbolis} other {simboliai}})": "Слишком коротко (минимум {count, число} {count, множественное число, один {символ} другой {символы}})",
        "Perkamiausi renginiai": "Бестселлеры",
        "Perkelta": "Взолнованный",
        "Perkeltas renginys": "Перемещенное событие",
        "Persiųsk bilietą draugui": "Переслать билет другу",
        "Persiųsk kuponą draugui": "Переслать подарочную карту другу",
        "Pertraukos": "Перерывы",
        "Peržiūrėk savo nurodytą el. pašto adresą dar kartą.": "Проверьте свой адрес электронной почты еще раз.",
        "Peržiūrėti visus": "Посмотреть все",
        "Pirk su MoQ ir 2 Eur susigrąžink atgal!": "Получите минимальный заказ или 2 евро в течение 2 евро!",
        "Pirkti bilietus VIP sąlygomis": "Купить билеты как VIP",
        "Pirkti bilietus įprastomis sąlygomis": "Купить билеты как простой пользователь",
        "Pirkti bilietus": "Купить билеты",
        "Pirkti kuponus": "Купить подарочные карты",
        "Pirkėjo el. paštas (neprivalomas)": "Электронная почта покупателя (необязательно)",
        "Pirkėjo el. paštas": "Электронная почта покупателя",
        "Pirkėjo mob. telefono nr.": "Покупатель моб. ",
        "Pirkėjo pavardė": "фамилия покупателя",
        "Pirkėjo vardas": "Имя покупателя",
        "PIRKTI VIP KLUBO/LOŽĖS BILIETUS": "КУПИТЬ БИЛЕТЫ В VIP-КЛУБ/ЛОДЖ",
        "Platintojas veikia kaip atsiskleidęs tarpininkas. Atsakomybę dėl įvykusio renginio ir jo kokybės prisiima Renginio Organizatorius. Dėl pinigų grąžinimo renginio nukėlimo ar atšaukimo atveju sprendimą priima organizatorius.": "Продавец Билетов (kakava.lt) действует как раскрытый агент. ",
        "Plačiau": "Более",
        "Populiarus": "Популярный",
        "Pradėkime nuo pradžių": "Начнем с самого начала",
        "Pranešk draugams!": "Расскажите своим друзьям!",
        "Prašome nurodyti Gimimo metus.": "Укажите год рождения.",
        "Prašome nurodyti miestą.": "Укажите город.",
        "Prašome nurodyti pinigų sumą": "Укажите сумму денег",
        "Prašome įvesti telefono numerį.": "Укажите номер телефона.",
        "Prašome pasirinkti banką.": "Выберите банк.",
        "Prašome pasirinkti mokėjimo būdą": "Выберите способ оплаты",
        "Prašome pasirinkti mokėjimo būdą.": "Выберите способ оплаты.",
        "Prašome pataisyti nurodytą reikšmę. Sumą po kablelio privalo sudaryti vienas arba du skaitmenys.": "Исправьте указанное значение. ",
        "Prašome pataisyti nurodytą reikšmę. Sumą turi sudaryti skaičiai.": "Исправьте указанное значение. ",
        "Prašome pataisyti nurodytą reikšmę. Varde negali būti skaičių.": "Исправьте указанное значение. ",
        "Prašome įvesti adresą.": "Введите адрес.",
        "Prašome įvesti el. pašto adresą.": "Введите адрес электронной почты.",
        "Prašome įvesti miestą.": "Войдите в город.",
        "Prašome įvesti pavadinimą.": "Введите имя.",
        "Prašome įvesti pavardę.": "Введите фамилию.",
        "Prašome įvesti slaptažodį.": "Введите пароль.",
        "Prašome įvesti vardą.": "Введите имя.",
        "Prašome įvesti įmonės adresą.": "Введите свой рабочий адрес.",
        "Prašome įvesti įmonės kodą.": "Введите код вашей компании.",
        "Prašome įvesti žinutę.": "Введите ваше сообщение.",
        "Priimta suma grynais": "Принимаемая сумма наличными",
        "Prisijungimas su Facebook nepavyko": "Не удалось войти через Facebook",
        "Prisijungimas": "Авторизоваться",
        "Prisijungimo klaida": "Ошибка входа",
        "Prisijungti": "Авторизоваться",
        "Prisijunk prie mūsų": "Присоединяйтесь к нам",
        "Prisijunk įvesdamas savo prisijungimo duomenis – galėsi išsisaugoti patikusius renginius bei įsigyti bilietus.": "Войдите, введя свои данные для входа – вы можете сохранять любимые мероприятия и покупать билеты.",
        "Prisijunk": "Авторизоваться",
        "Prisiminti mane": "Запомнить меня",
        "Pritaikyta nuolaidų": "Скидки применяются",
        "Privalomas laukas": "Обязательное поле",
        "Redaguoti": "Редактировать",
        "Registracija sėkminga": "Вы успешно зарегистрировались",
        "Registracija sėkminga. Patikrink paštą ir aktyvuok savo vartotoją.": "Вы успешно зарегистрировались. ",
        "Registracijos klaida": "Ошибка регистрации",
        "Registruokis": "Зарегистрироваться",
        "Registruotis": "Зарегистрироваться",
        "Reikės sąskaitos faktūros": "Нужен счет",
        "Renginiai": "События",
        "renginiai": "события",
        "Renginio akimirkos": "Моменты мероприятия",
        "Renginio pradžia": "Начинать",
        "Renginio tipas": "Тип события",
        "Renginio trukmė": "Продолжительность",
        "renginių": "события",
        "Renginių bilietai – pigiau! Geriausius muzikos, teatro, sporto ir kultūros renginių pasiūlymus ir kitas akcijas rasite čia.": "Билеты на мероприятия – дешевле! ",
        "Renginys": "Событие",
        "renginys": "Событие",
        "Rezervacijos dokumentas": "Документ бронирования",
        "Rinkis bilietus iš naujo": "Повторно выбрать билеты",
        "Rinktis kuponą": "Выберите подарочную карту",
        "Rinktis vietą ir laiką": "Выберите место и время",
        "Rūšiuoti pagal": "Сортировать по",
        "Saugoti": "Сохранять",
        "Saugumo sumetimais bilietų ir sąskaitų faktūrų neberodome": "По соображениям безопасности мы больше не показываем билеты и счета.",
        "Sektorius": "Сектор",
        "Senas slaptažodis": "Старый пароль",
        "Sesija baigėsi – prisijunk iš naujo.": "Сеанс завершен – войдите снова.",
        "Sesijos laikas baigėsi": "Время сеанса истекло",
        "Siųsti atstatymo nuorodą": "Отправить ссылку для сброса",
        "Siųsti bilietus el.paštu": "Отправить билеты по электронной почте",
        "Siųsti kuponus el.paštu": "Отправить купон по электронной почте",
        "Siųsti pažymėtus bilietus draugui": "Поделитесь проверенными билетами с другом",
        "Siųsti pažymėtus kuponus draugui": "Отправить отмеченные подарочные карты другу",
        "Siųsti": "Отправлять",
        "Skirtingos vietos": "Разные локации",
        "Slaptažodis pakeistas sėkmingai.": "Пароль успешно изменен.",
        "Slaptažodis pakeistas": "Пароль изменен",
        "Slaptažodis": "Пароль",
        "Slaptažodį turi sudaryti mažiausiai 6 simboliai.": "Пароль должен быть длиной не менее 6 символов.",
        "Slaptažodžio atkūrimas": "Восстановление пароля",
        "Slaptažodžio keitimas nepavyko": "Сменить пароль не удалось",
        "Slaptažodžio keitimas": "Изменение пароля",
        "Slaptažodžio priminimas": "Напоминание пароля",
        "Spausdinti bilietus": "Распечатать билеты",
        "Spausdinti kvitą": "Распечатать чек",
        "Spausdinti kuponus": "Распечатать подарочные карты",
        "Suma": "Общий",
        "Supakuok savo bilietus": "Подарить билет",
        "Supakuoti": "Обернуть билет",
        "Susikurk naują slaptažodį ir jį pakartok.": "Создайте новый пароль и повторите его.",
        "Susipažinau su pirkimo <1>taisyklėmis</1>": "Я прочитал покупку <1>правила</1>",
        "Susipažinau su <1>taisyklėmis</1>": "Я прочитал <1>правила</1>",
        "Susisiek": "Связаться с нами",
        "Sutinku": "Я согласен",
        "Svarbi informacija": "Важная информация",
        "Sąskaita faktūra": "Счет",
        "Taip": "Да",
        "Tam, kad taptum kakava.lt vartotoju, mes turime turėti tavo el.pašto adresą. Neturėdami tavo el. pašto, negalėsime tau išsiųsti bilietų, pateikti specialių pasiūlymų ar kitos informacijos. Todėl spausk dar kartą mygtuką, sutik mums duoti savo duomenis, ir prisijunk prie mūsų!": "Чтобы стать пользователем kakava.lt, нам нужен ваш адрес электронной почты. ",
        "Tavo bilietai": "Ваши билеты",
        "Tavo duomenys atnaujinti sėkmingai.": "Ваши данные успешно обновлены.",
        "Tavo el. paštas": "Ваш адрес электронной почты",
        "Tavo kakava.lt nupirkti bilietai į renginį tuoj kažką labai pradžiugins. Įrašyk sveikinimo tekstą ir nusiųsk bilietą draugui ar artimajam!": "Порадуйте кого-нибудь билетами на мероприятия, купленными на kakava.lt. ",
        "Tavo kakava.lt nupirkti kuponai tuoj kažką labai pradžiugins. Įrašyk sveikinimo tekstą ir nusiųsk kuponą draugui ar artimajam!": "Порадуйте кого-нибудь подарочным купоном, который вы купили на kakava.lt. ",
        "Tavo krepšelis": "Ваша корзина",
        "Tavo kuponai": "Ваши подарочные карты",
        "Tavo žinutė draugui": "Ваше сообщение другу",
        "Tačiau primename, kad savo bilietus ir sąskaitą faktūrą visada rasi el. pašte.": "Помните, что вы всегда сможете найти свои билеты и счет на электронную почту.",
        "Trumpam užsnūdai? Sesija baigėsi – prisijunk iš naujo.": "Вздремнуть? ",
        "Trūksta tik varnelės, kad galėtum tęsti!": "Галочка отсутствует, чтобы продолжить!",
        "Turi būti pažymėta": "Должен быть проверен",
        "Tęsti apmokėjimą": "Продолжить оплату",
        "Tęsti": "Продолжать",
        "Uh-oh. Kortelė NEDRAUGAUJA su internetiniais pirkimais. Savo banke pakeisk kortelės nustatymus, kad leistų atsiskaityti internetu.": "О-о. ",
        "Užimtos vietos": "Занятые места",
        "Užsiregistruok": "Зарегистрироваться",
        "Užsiregistruok: prisiminsime tave ir nereikės kaskart vesti duomenų iš naujo.": "Экономьте свое время – записывайтесь! ",
        "Užsisakyti": "Подписаться",
        "VIP ložės ir klubai": "VIP-залы и клубы",
        "Vaikai įleidžiami nemokamai": "Дети принимаются бесплатно.",
        "Valio! Slaptažodis sėkmingai pakeistas.": "Ура! ",
        "Vardas": "Имя",
        "Vartotojas aktyvuotas. Prašome prisijungti.": "Пользователь активирован. ",
        "Vartotojas nėra aktyvuotas. Registracijos metu tau išsiuntėme el.laišką, prašome paspausti jame esančią nuorodą. Tada galėsi prisijungti.": "Пользователь не активирован. ",
        "Vartotojo aktyvacija": "Активация пользователя",
        "Vartotojo aktyvavimas nepavyko.": "Активация пользователя не удалась.",
        "Vartotojo vardas arba slaptažodis yra neteisingi.": "Имя пользователя или пароль неверны.",
        "Vieta": "Место",
        "Visi sektoriai": "Все сектора",
        "Visi": "Все",
        "<0>Viskas, ko reikia – sekundė kantrybės! Lange bilietai atsiras iškart, kai sugeneruosime.</0><1>Taip pat bilietus atsiųsim el. paštu bei visada rasi savo paskyroje.</1>": "Все, что вам нужно, это секунда терпения! <1></1>Мы также отправим ваши билеты по электронной почте, и вы всегда найдете их в своем аккаунте.",
        "<0>Viskas, ko reikia – sekundė kantrybės! Lange bilietai atsiras iškart, kai sugeneruosime.</0><1>Taip pat bilietus atsiųsime el. paštu.</1>": "<0>Все, что вам нужно, это секунда терпения! </0><1>Мы также отправим ваши билеты по электронной почте.</1>",
        "<0>Viskas, ko reikia – sekundė kantrybės! Lange kuponai atsiras iškart, kai sugeneruosime.</0><1>Taip pat kuponus atsiųsim el. paštu bei visada rasi savo paskyroje.</1>": "<0>Все, что вам нужно, это секунда терпения! </0><1>Мы также отправим подарочные карты по электронной почте, и вы всегда сможете найти их в своей учетной записи.</1>",
        "<0>Viskas, ko reikia – sekundė kantrybės! Lange kuponai atsiras iškart, kai sugeneruosime.</0><1>Taip pat kuponus atsiųsime el. paštu.</1>": "<0>Все, что вам нужно, это секунда терпения! </0><1> Мы также отправим подарочные карты по электронной почте.</1>",
        "arba": "или",
        "bilietai": "билеты",
        "bilietas": "билет",
        "bilietų": "билеты",
        "iki": "к",
        "iš": "из",
        "kakava-lt.pdf": "какава-lt.pdf",
        "kakava-lt_ataskaita_": "kakava-lt_report_",
        "kakava-lt_dienos-ataskaita.pdf": "kakava-lt_report-of-the-day.pdf",
        "kakava-lt_rezervacija.pdf": "kakava-lt_reservation.pdf",
        "kakava.lt | Kultūrinio gyvenimo vitaminai": "kakava.lt Витамины культурной жизни",
        "metai": "годы",
        "metų amžiaus": "лет",
        "metų": "годы",
        "min.": "мин.",
        "nauju slaptažodžiu": "с новым паролем",
        "ne": "нет",
        "neprivalomas": "необязательный",
        "nuo brangiausio iki pigiausio": "цена: сначала самая высокая",
        "nuo greičiausiai prasidedančio": "время: начнётся в ближайшее время",
        "nuo pigiausio iki brangiausio": "цена: сначала самая низкая",
        "nuo vėliausiai prasidedančio": "время: начиная с самого последнего",
        "nėra": "никто",
        "Čia patogiai pateiksime tavo išsaugotus renginius. Radęs patinkantį renginį, spustelk širdutę jo išsaugojimui.": "Здесь вы найдете свои любимые события. ",
        "Čia patogiai rasi savo jau įsigytus bilietus. Kaskart nusipirkus bilietus į patinkantį renginį, bilietai bus čia.": "Здесь вы найдете все купленные вами билеты.",
        "Įdėti į krepšelį": "Добавить в корзину",
        "Įmonės kodas": "Номер компании",
        "Įmonės pavadinimas": "Название компании",
        "Įpakavimo kaina": "Цена упаковки",
        "Įvesk el. pašto adresą": "Введите адрес электронной почты",
        "Įveskite nuolaidos kodą": "Введите код скидки",
        "Įvykęs renginys": "Мероприятие окончено",
        "Šiam renginiui vietos parenkamos automatiškai": "Места на это мероприятие выбираются автоматически",
        "Šis laukas yra privalomas.": "Это поле является обязательным.",
        "„<1>{{eventTitle}}</1>“ jau greitai jūsų mieste! Bilietus į artimiasią renginį įsigykite greičiau ir paprasčiau per kakava.lt.": "„<1>{{eventTitle}}</1>«Скоро в вашем городе! ",
        "„{{eventTitle}}“ jau greitai jūsų mieste! „{{eventTitle}}“ bilietai į renginį greičiau ir paprasčiau per kakava.lt.": "„{{eventTitle}}«Скоро в вашем городе! {{eventTitle}}«Билеты на мероприятия быстрее и проще с kakava.lt.",
        "Jūsų naršyklė blokuoja Google": "Ваш браузер блокирует Google",
        "Ginčai dėl sutarties netinkamo vykdymo ar nevykdymo ne teisme nagrinėjami Lietuvos Respublikos vartotojų teisių apsaugos įstatymo nustatyta tvarka Valstybinėje vartotojų teisių apsaugos tarnyboje, adresu Vilniaus g. 25, 01402 Vilnius, el. p. tarnyba@vvtat.lt, tel. (8 5) 262 67 51, faks. (8 5) 279 1466, interneto svetainė www.vvtat.lt. Elektroniniu būdu prašymą galite pateikti per EGS platformą http://ec.europa.eu/odr/.": "Споры о ненадлежащем исполнении или неисполнении договора во внесудебном порядке рассматриваются в порядке, установленном Законом Литовской Республики о защите прав потребителей, в Государственной службе защиты прав потребителей, ул. ",
        "Telefono nr.": "Номер телефона.",
        "Apmokėta dovanų kuponu": "Оплачено подарочной картой",
        "Dovanų kuponas": "Подарочная карта",
        "Įveskite dovanų kupono numerį": "Введите номер подарочной карты",
        "Dovanų kupono numeris susideda tik iš skaičių": "Номер подарочной карты состоит только из цифр.",
        "Toliau": "Продолжать",
        "Dovanų kupono Nr.:": "Номер подарочной карты:",
        "Suma:": "Количество:",
        "Ačiū, Dovanų kuponas panaudotas": "Спасибо, Подарочная карта используется.",
        "Dovanų kuponais sumokėta:": "Подарочная карта была использована для оплаты:",
        "Liko mokėti:": "Осталось оплатить:",
        "Dovanų kupono Nr.": "Номер подарочной карты",
        "Pridėti dovanų kuponą": "Добавьте подарочную карту",
        "Prisijunk su Facebook": "Войти через Facebook",
        "Prisijunk su Google": "Войти через Google",
        "Susipažinau su pirkimo taisyklėmis": "Я прочитал правила покупки",
        "Susipažinau su taisyklėmis": "Я прочитал правила",
        "Susipažinau su pirkimo": "Я прочитал правила",
        "taisyklėmis": "покупки",
        "Su MoQ atgauk 1%* (išimtys <1>taisyklėse</1>)": "При заказе минимального заказа вернитесь 1% * (исключения <1>в правилах</1>)",
        "Įveskite Dovanų kupono kodą": "Введите код подарочного купона",
        "Dovanų kupono numeris susideda iš 14 skaitmenų": "Код подарочного купона состоит из 14 цифр.",
        "Dovanų kupono kodas:": "Номер подарочного купона:",
        "Dovanų kupono kodas": "Номер подарочного купона",
        "Bilietų grąžinimas": "Возврат билетов",
        "Laukiame apmokėjimo": "Ожидание оплаты",
        "Laukiame krepšelio apmokėjimo. Prašome neuždaryti lango.": "Ожидание оплаты в корзине. ",
        "Įvesk išankstinio pardavimo kodą": "Введите код предпродажи",
        "Išankstinio pardavimo kodas": "Предпродажный код",
        "Išankstinio pardavimo kodas neteisingas.": "Предпродажный код неправильный",
        "Kodas": "Код",
        "Patvirtinti": "Подтверждать",
        "Grąžinti": "Возвращать деньги",
        "Ar daryti grąžinimą?": "Сделать возврат?",
        "Ar grąžinimas atliktas?": "Возврат произведен?",
        "Atsidarykite Grąžinimo dokumentą (parsiųstas .pdf failas) ir atlikite pinigų grąžinimą klientui.": "Откройте документ о возврате (загруженный файл в формате PDF) и верните деньги клиенту.",
        "Bilietai grąžinti": "Билеты возвращены",
        "Ar tikslinti grąžinamų bilietų informaciją?": "Нужно ли обновлять информацию о билетах?",
        "Įveskite grąžinamo bilieto numerį": "Пожалуйста, введите номер билета возврата",
        "Bilieto numeris susideda tik iš skaičių": "Номер билета состоит только из цифр",
        "Bilieto numeris": "Номер билета",
        "Pridėti bilietą": "Добавить билет",
        "Bilieto Nr.:": "Номер билета:",
        "Grąžinamų bilietų informacija": "Информация о возвратном билете",
        "Renginiai, kurių bilietus galima grąžinti": "Мероприятия, билеты на которые подлежат возврату",
        "Šiuo metu nėra renginių, kurių bilietus galima grąžinti": "На данный момент нет мероприятий, билеты на которые подлежат возврату.",
        "Grynieji pinigai / Mokėjimo kortelė": "Наличные/Платежная карта",
        "Renginiai pagal kategorijas": "События по категориям",
        "Apie festivalį": "О фестивале",
        "„{{groupTitle}}“ jau greitai jūsų mieste! Bilietus į artimiausią renginį įsigykite greičiau ir paprasčiau per kakava.lt.": "„{{groupTitle}}«Скоро приедет в ваш город! ",
        "Festivaliai": "Фестивали",
        "Kaina ": "Цена ",
        "Nemokamas": "Бесплатно",
        "Gauti bilietus": "Получить билеты",
        "Asmeniniai pasiūlymai": "Персональные скидки",
        "Pagalba kasoms": "Поддержка кассовых сборов",
        "Pristatymo paslauga": "Физическая доставка",
        "Gatvė": "Улица",
        "Komentaras": "Комментарий",
        "Papildomos paslaugos": "Дополнительные услуги",
        "Klubo/ložės duomenys": "Данные о клубе/ложе",
        "Kodas/Kortelės nr.": "Номер кода/карты.",
        "Grįžti": "Закрывать",
        "Klubo/ložės pagal nurodytus duomenis rasti nepavyko.": "Клуб/лодж не найден.",
        "Miestą turi sudaryti iki 100 simbolių.": "Длина города должна быть не более 100 символов.",
        "Įmonės adresą turi sudaryti iki 100 simbolių.": "Адрес компании должен содержать до 100 символов.",
        "Įmonės kodą turi sudaryti iki 20 simbolių.": "Номер компании должен содержать до 20 символов.",
        "Sumokėta": "Оплаченный",
        "Bilietų kiekio keisti negalite": "Изменить количество билетов невозможно.",
        "Kakava LT, Žalgirio 135, Vilnius, Lietuva": "Kakava LT, Жальгирио 135, Вильнюс, Литва",
        "Pridėti į Apple Wallet": "Добавить в Apple Wallet",
        "Pridėti bilietus į Apple Wallet": "Добавьте билеты в Apple Wallet",
        "Šią svetainę saugo reCAPTCHA ir Google. <1>Privatumo politika</1> bei <3>Paslaugų teikimo sąlygos</3>.": "Этот сайт защищен reCAPTCHA и Google. <1>политика конфиденциальности</1> и <3>Условия использования</3>.",
        "Rezervacijų apmokėjimas": "Оплата бронирования",
        "Įveskite rezervacijos numerį": "Введите номер бронирования",
        "Rezervacijos numeris susideda tik iš skaičių": "Номер бронирования состоит только из цифр",
        "Rezervacijos numeris": "Номер бронирования",
        "Tik Jūsų sutikimu mes naudojame slapukus, su tikslu pagerinti Jūsų naršymo kokybę bei tobulinti mūsų paslaugų turinį bei naršymo saugumą. Be to, būtinieji slapukai įgalina pagrindines mūsų svetainės funkcijas; ji negalėtų tinkamai veikti be šių slapukų, todėl šiems slapukams Jūsų sutikimas nėra būtinas; juos galima išjungti tik pakeitus naršyklės nuostatas.": "Мы используем файлы cookie только с вашего согласия с целью улучшения качества вашего просмотра, а также содержания наших услуг и вашей безопасности просмотра. ",
        "Tik Jūsų sutikimu mes naudojame slapukus, su tikslu pagerinti Jūsų naršymo kokybę bei tobulinti mūsų paslaugų turinį bei naršymo saugumą. Detalesnė informacija mūsų <1>privatumo politikoje</1>. Be to, būtinieji slapukai įgalina pagrindines mūsų svetainės funkcijas; ji negalėtų tinkamai veikti be šių slapukų, todėl šiems slapukams Jūsų sutikimas nėra būtinas; juos galima išjungti tik pakeitus naršyklės nuostatas.": "Мы используем файлы cookie только с вашего согласия с целью улучшения качества вашего просмотра, а также содержания наших услуг и вашей безопасности просмотра.  <1>политика конфиденциальности</1>. ",
        "Koreguoti pasirinkimą": "Отрегулируйте выбор",
        "Sutinku su visais": "Разрешить все файлы cookie",
        "Ši svetainė naudoja slapukus": "Используемые файлы cookie",
        "Sutinku su pasirinktais": "Разрешить выбранные файлы cookie",
        "Detaliau": "Более",
        "Pavadinimas": "Имя",
        "Aprašymas": "Описание",
        "Valdytojas": "Менеджер",
        "Trukmė": "Продолжительность",
        "Palaukime": "Пожалуйста, подождите",
        "kol gausime atsakymą iš banko": "пока мы не получим подтверждение от банка",
        "Pridėtį į kalendorių": "Сохранить дату",
        "Uždaryti": "Закрывать",
        "Kainos": "Цены",
        "Vietos tipai": "Типы сидений",
        "Vietų žymės": "Тип сиденья",
        "Vietos": "Сиденья",
        "Nuolaidų pasirinkimai kitame žingsnyje": "Варианты скидок находятся на следующем этапе.",
        "Pirkti papildomas renginio paslaugas": "Дополнительные услуги",
        "PVM mokėtojo kodas": "НДС №",
        "Sektoriai": "Секторы",
        "Nuolaidas galėsi pritaikyti krepšelyje": "Скидки можно применить в корзине.",
        "Ši nuoroda negalioja.": "Ссылка недействительна",
        "Bilietus į savo norimą renginį kviečiame įsigyti renginių namuose internete:": "Приобретите билет в доме мероприятий:",
        "Įsigyti bilietą": "Купить билет",
        "Pardavimas sustabdytas": "Продажа приостановлена",
        "Pardavimo operacija nepavyko, krepšelis laukimo stadijoje. Jei norite iš naujo pradėti pardavimą, <1>spauskite čia</1>": "Операция продажи не удалась, корзина находится на рассмотрении.  <1>кликните сюда</1>",
        "Įveskite kodą": "Введите код разблокировки",
        "Taikyti": "Применять",
        "Pasirink datą": "Выберите дату",
        "Pasirink laiką": "Выберите время",
        "Laikas": "Время",
        "Atsiskaitymas dovanų kuponu apmokėjimo lange": "Вы можете оплатить подарочным сертификатом в окне оплаты.",
        "Dovanų kuponas sėkmingai pratęstas": "Продление подарочного сертификата выполнено успешно.",
        "Suformuotos rezervacijos keisti negalite.": "Вы не можете изменить уже сформированное бронирование."
    }
};
